import store from '@/store/index'
import axios from 'axios'
import { ElMessage } from 'element-plus'

const axiosIns = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? '/apis/api/v1/' : location.origin + '/api/v1/',
  timeout: 60 * 1000
})

// 请求拦截器
axiosIns.interceptors.request.use((request) => {
  if (store.state.token) {
    // eslint-disable-next-line no-param-reassign
    request.headers.Authorization = 'Bearer ' + store.state.token
  }
  return request
})

// 响应拦截器
axiosIns.interceptors.response.use(
  response => handleResponse(response),
  error => {
    const { response } = error
    const { data, status } = response
    switch (status) {
      case 400:
        ElMessage.error(data.message)
        break
      case 404:
        ElMessage.error(data.message)
        break
      case 500:
        ElMessage.error(data.message)
        break
      case 401:
        ElMessage.error('未登录，请先登录')
        setTimeout(() => { window.location.href = '/login' }, 2000)
        break
      case 403:
        ElMessage.error(data.message)
        break
      default:
        break
    }
    return Promise.reject(error)
  }
)

const handleResponse = response => {
  const { status, data } = response
  if (data.message) ElMessage.success(data.message)
  return data
}

export default axiosIns
